import Loading from '@/components/Loading'

export default {
  components: {
    Loading
  },

  data () {
    return {
      miAPI: null,
      isShowSpinner: false,
      isLoadVideo: false,
      isActiveVideo: false,
      isVideoComplete: false,
      isDoneWaiting: false,
      isLoadVideoError: false
    }
  },

  methods: {
    onInitAPI (api) {
      this.miAPI = api
    },

    onStartWaiting () {
      this.isDoneWaiting = false
    },

    onDoneWaiting () {
      this.isDoneWaiting = true
    },

    onLoadVideo () {
      this.isLoadVideo = true
    },

    onShowSpinner () {
      this.isLoadVideo = false
      this.isActiveVideo = true
      this.isShowSpinner = true
    },

    onHideSpinner () {
      this.isShowSpinner = false
    },

    videoComplete () {
      this.isVideoComplete = true
    },

    videoUnComplete () {
      this.isVideoComplete = false
    },

    inActiveVideo () {
      this.isActiveVideo = false
    },

    resetVideoState () {
      this.inActiveVideo()
      this.videoUnComplete()
    },

    loadVideoError () {
      this.isLoadVideoError = true
    }
  }
}
