<template>
  <div id="advertiser">
    <button
      ref="adButton"
      type="button"
      id="reward-play-btn"
      class="btn-modal-recommend"
      v-html="'動画を見てシェア'"
      @click="onInitVideo"
    ></button>

    <button id="reward-error" class="hidden" @click="onRewardError"></button>
    <button
      id="reward-complete"
      class="hidden"
      @click="onRewardComplete"
    ></button>
    <button id="reward-cancel" class="hidden" @click="onRewardCancel"></button>
  </div>
</template>

<script>
export default {
  name: 'VideoAdvertise',

  data () {
    return {
      // placementId: 'Yce2cipwYKVz',
      rootTimeout: 20000,
      adVideoDefineAdSlot: process.env.VUE_APP_VIDEO_ADVERTISE_SLOT || '',
      adVideoRender: process.env.VUE_APP_VIDEO_ADVERTISE_RENDER || ''
    }
  },

  computed: {
    videoLoadingTimeout () {
      const timeOutStorage =
        process.env.VUE_APP_VIDEO_ADVERTISE_LOADING_TIMEOUT || this.rootTimeout
      const timeoutVal = Number(timeOutStorage)
      return !isNaN(timeoutVal) ? timeoutVal : this.rootTimeout
    }
  },

  methods: {
    onInitVideo () {
      // this.$emit('adVideoInit')
      // this.$emit('adStartWaiting')

      // setTimeout(() => {
      //   this.$emit('adDoneWaiting')
      // }, this.videoLoadingTimeout)

      // try {
      //   const el = document.createElement('div')
      //   document.body.append(el)

      //   const player = (window.playerPro = window.playerPro || [])
      //   player.push({
      //     id: this.placementId,
      //     after: el,
      //     init: (api) => {
      //       if (!api) return

      //       this.$emit('adAPI', api)

      //       api.on('AdStarted', () => {
      //         this.$emit('adVideoStarted')
      //       })

      //       api.on('AdVideoStart', () => {
      //         api.setAdVolume(0)
      //       })

      //       api.on('AdVideoComplete', () => {
      //         this.$emit('adVideoComplete')
      //         api.remove()

      //         const videoDisplay = document.querySelector(`[data-id=${this.placementId}]`)
      //         if (!videoDisplay) return

      //         videoDisplay.remove()
      //       })
      //     }
      //   })
      // } catch {
      //   this.$emit('adError')
      // }

      const rewardPlayBtn = document.getElementById('reward-play-btn')
      const adVideoRender = this.adVideoRender
      try {
        window.alm.que.push(function () {
          rewardPlayBtn.disabled = true
          window.alm.render(adVideoRender)
        })

        window.alm.que.push(function () {
          window.alm.getAdTracker(adVideoRender).then(function (adTracker) {
            adTracker.track(window.alm.Events.AD_LOADING_FAILED, function () {
              //  This section describes what to do when there are no ads or an error occurs.
              //  Michao retrieves ads from several different ad sources, but there are times
              //  when the connection may be slow depending on the specific ad source, and the associated poor ad return rate.
              console.warn('No ads here...')
              rewardPlayBtn.disabled = false
              const rewardError = document.getElementById('reward-error')
              rewardError.click()
            })

            adTracker.track(window.alm.Events.AD_ERROR, function () {
              console.warn('AD_ERROR')
              rewardPlayBtn.disabled = false
              const rewardError = document.getElementById('reward-error')
              rewardError.click()
            })

            adTracker.track(window.alm.Events.REWARDED, function () {
              /* Describe the reward process here. Customize it as you like. */
              console.log('Rewarded!!!')
              rewardPlayBtn.disabled = false
              const rewardComplete = document.getElementById('reward-complete')
              rewardComplete.click()
            })

            adTracker.track(
              window.alm.Events.SOUND_CONFIRMATION_CANCEL,
              function () {
                rewardPlayBtn.disabled = false
                const rewardCancel = document.getElementById('reward-cancel')
                rewardCancel.click()
              }
            )
          })
        })
      } catch {
        this.$emit('adLoadError')
      }
    },
    onInitReward () {
      const adVideoDefineAdSlot = this.adVideoDefineAdSlot
      const adVideoRender = this.adVideoRender

      window.alm = window.alm || { que: [] }
      window.alm.que.push(function () {
        window.alm.defineAdSlot(adVideoDefineAdSlot, adVideoRender)
      })
    },
    onRewardError () {
      this.onInitReward()
      this.$emit('adVideoComplete')
      this.$emit('adLoadError')
    },
    onRewardComplete () {
      this.onInitReward()
      this.$emit('adVideoComplete')
    },
    onRewardCancel () {
      this.onInitReward()
    }
  },

  mounted () {
    this.onInitReward()
  }
}
</script>

<style lang="scss" scoped>
.hidden {
  display: none;
}
</style>
