<template>
  <section>
    <!-- SHARE ON TWITTER -->
    <template>
      <button
        class="btn-social twitter"
        @click="openModalShare"
        >
        <img src="../../assets/images/icon/icon-x.svg" alt="icon">X(旧Twitter)で紹介
        </button>
    </template>

    <!-- SHARE ON LINE -->
    <ShareNetwork
      v-if="!checkWebview()"
      class="btn-line btn-social"
      network="Line"
      :title="'ポイ活サイトのCHIBIJOB（ちびじょぶ）でお小遣いGET！！\n' +
                    '簡単に登録出来て、すぐにお小遣いが稼げちゃう！\n' +
                    '今すぐ登録しよう '"
      :url="currentURL + '/registration?invite=' + $store.state.auth.profile.ref_code +'&section_id=' + Date.now()">
      <img src="../../assets/images/icon/icon-line.svg" alt="icon">LINEで紹介
    </ShareNetwork>

    <!-- SHARE LINE ON APP -->
    <button
      v-else
      class="btn-social btn-line"
      @click="handleShareLine">
      <img src="../../assets/images/icon/icon-line.svg" alt="icon">LINEで紹介
    </button>

    <!-- MODAL SHOW NOTI SHARE TWITTER -->
    <Modal v-if="showNoti"
           class="modal-center cate-pink"
           @close-modal="handleCloseModal"
           >
      <div slot="body" class="responsive-height">
        <!-- NOTI SHARE TWITTER -->
        <div class="block-vertical">
          <ListItem :list="listRecommendModal" titleModal="スタッフいちおし!"  hasBackground mode="vertical" isChangeBackground isInModal keepModal />
        </div>
        <div class="warning-share">
          <p v-if="!isShare && !isVideoComplete && !isShowSpinner" class="content-before-share">動画を見てシェアすると5ポイントゲット！</p>
          <p v-if="isShare" class="content-before-share">X(旧Twitter)へポストの送信に成功しました。</p>
          <p v-if="isShowSpinner" class="content-before-share">動画広告取得中...</p>

          <VideoAdvertise
            v-if="!isVideoComplete && !isShare"
            v-show="!isShowSpinner"
            @adAPI="onInitAPI"
            @adVideoComplete="videoComplete"
            @adVideoInit="onShowSpinner"
            @adVideoStarted="onStartVideo"
            @adError="onAdError"
            @adNotResponse="onAdError"
            @adStartWaiting="onStartWaiting"
            @adDoneWaiting="onDoneWaiting"
            @adLoadError="loadVideoError"
          />

          <p v-if="isVideoComplete && !isLoadVideoError" class="content-before-share">動画視聴が完了しました。Xでシェアして5ポイントをゲットしよう！</p>
          <p v-else-if="isVideoComplete && isLoadVideoError" class="content-before-share">Xでシェアして5ポイントをゲットしよう！</p>

          <button
            v-if="isVideoComplete"
            class="btn-modal-recommend"
            @click="handleShare({ isWatchVideo: true })"
            v-html="'シェアしてポイントゲット'"
          />

          <button
            v-if="!isShare && !isVideoComplete"
            v-show="!isShowSpinner"
            class="btn-modal-recommend"
            @click="handleShare({ isWatchVideo: false })"
            v-html="'動画を見ないでシェア'"
          />
        </div>
      </div>
    </Modal>

    <!-- MODAL AD VIDEO -->
    <div :id="adVideoRender"></div>

    <Loading v-if="isShowSpinner"/>
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { RESPONSE_STATUS } from '@/enum/response'
import Common from '@/mixins/common.mixin'
import VideoAds from '@/mixins/videoAds.mixin'
import VideoAdvertise from '@/components/advertising-detail/VideoAdvertise'
import Modal from '@/components/advertising-detail/Modal'
import ListItem from '@/components/ListItem.vue'

export default {
  name: 'Tab1',
  components: {
    VideoAdvertise,
    Modal,
    ListItem
  },
  mixins: [Common, VideoAds],

  computed: {
    ...mapState('auth', ['profile']),
    ...mapState('advertises', ['listRecommendModal', 'isKeepModal', 'isShare', 'advIdOpenShared'])
  },

  data () {
    return {
      currentURL: window.location.origin,
      windowReference: null,
      showNoti: false,
      adVideoRender: process.env.VUE_APP_VIDEO_ADVERTISE_RENDER || ''
    }
  },

  watch: {
    'isDoneWaiting' (isDone) {
      if (isDone && !this.isLoadVideo) {
        this.onHideSpinner()
        this.videoComplete()

        if (!this.miAPI) return
        this.miAPI.remove()
      }
    }
  },

  created () {
    if (this.isKeepModal && !this.isShare && !this.advIdOpenShared) {
      this.showNoti = true
    }
  },

  methods: {
    ...mapActions('shareSns', ['authorizeTwitter', 'setTwitterError']),

    onStartVideo () {
      this.onLoadVideo()
      this.onHideSpinner()
    },

    onAdError () {
      this.handleCloseModal()
      this.setTwitterError(true)
      this.onHideSpinner()
    },

    async handleShare ({ isWatchVideo = false }) {
      this.showNoti = false
      // open new tab on IOS
      if (this.checkDevice() && !this.checkWebview()) {
        this.windowReference = window.open('', '_blank')
      }
      const targetUrl = this.currentURL + '/registration?invite=' + this.profile.ref_code + '&section_id=' + Date.now()
      const content = '【PR】ポイ活サイトのCHIBIJOB（ちびじょぶ）でお小遣いGET！！\n' +
        '簡単に登録出来て、すぐにお小遣いが稼げちゃう！\n' +
        '今すぐ登録しよう\n' + '#chibijob #ポイ活 #懸賞 #懸賞垢さんと繋がりたい #懸賞仲間募集中\n' + targetUrl

      const params = {
        type: 1,
        content_share: content,
        device_id: navigator.userAgent,
        watched_video: +isWatchVideo
      }

      // save local storage to push BE
      localStorage.setItem('urlShare', targetUrl)
      localStorage.removeItem('titleAd')

      // open share on webview
      if (this.checkWebview()) {
        this.windowReference = window.open('', '_self')
      }

      const authTwitter = await this.authorizeTwitter(params)

      if (authTwitter.status === RESPONSE_STATUS.OK) {
        if (authTwitter.data && authTwitter.data.link_share) {
          if (this.checkWebview()) {
            this.windowReference.location = authTwitter.data.link_share
          } else {
            if (this.checkDevice()) {
              this.windowReference.location = authTwitter.data.link_share
            } else {
              window.open(authTwitter.data.link_share, '_blank')
            }
          }
        } else {
          if (this.checkDevice() || this.checkWebview()) {
            this.windowReference.close()
          }
          // emit event to show modal noti success
          this.$emit('notiShare')
        }
        this.inActiveVideo()
        this.videoUnComplete()
        this.$store.dispatch('advertises/setHandleShare', true)
      } else if (authTwitter.status === RESPONSE_STATUS.UNPROCESSABLE_CONTENT) {
        // Check IOS to close tab before change route
        if (this.checkDevice() || this.checkWebview()) {
          this.windowReference.close()
        }

        // redirect page error on app
        if (this.checkWebview()) {
          this.openPageNative('PageError')
        }
      }
    },

    handleShareLine () {
      // link to line
      let linkLine = 'http://line.me/R/msg/text/?'

      // add content share to link
      const targetUrl = this.currentURL + '/registration?invite=' + this.profile.ref_code
      linkLine = linkLine + 'ポイ活サイトのCHIBIJOB（ちびじょぶ）でお小遣いGET！！\n' +
        '簡単に登録出来て、すぐにお小遣いが稼げちゃう！\n' +
        '今すぐ登録しよう\n' + targetUrl

      // encoded url share
      const encoded = encodeURI(linkLine)

      // event to open browser to open app line
      this.triggerEventApp(
        'OPEN_BROWSER',
        { url: encoded }
      )
    },

    openModalShare () {
      this.showNoti = true
      this.$store.dispatch('advertises/getListRecommendModal')
    },

    handleCloseModal () {
      this.showNoti = false
      this.resetVideoState()
      this.$store.dispatch('advertises/setKeepModal', false)
    }
  }
}
</script>

<style lang="scss" scoped>
section {
  padding: 24px 18px;
  background: #FAFAFA;
  border-radius: 6px;
  &:has(.btn-social-hidden) {
    background: #ffffff;
     @media #{$info-phone} {
      background: #FAFAFA;
    }
  }
  .btn-social {
    font-family: $font-family-title;
    @include font-size(28px);
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 456px;
    width: 100%;
    margin: 12px auto;
    padding: 17px 0;
    border-radius: 6px;
    border: 2px solid #1D9BF1;
    background: rgba(#1C9BF1, 0.05);
    color: #1C9BF1;
    @media #{$info-phone} {
      max-width: 100%;
    }
    &.btn-line {
      background: rgba(#06C755, 0.05);
      border: 2px solid #2DC755;
      color: #06C755;
      display: none;
      @media #{$info-phone} {
        display: flex;
      }
    }
    &.twitter {
      border: 2px solid #000000;
      background-color: rgba(0, 0, 0, 0.05);
      color: #000000;
    }
    img {
      margin-right: 24px;
      width: 36px;
    }
  }
  .warning-share {
    @include font-size(25px);
    @media #{$info-phone} {
      font-size: 20px;
    }
    .confirm {
      padding: 10px 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 20px auto 0;
      cursor: pointer;
      background: #C66C44;
      color: #ffffff;
      border-radius: 6px;
      @media #{$info-phone} {
        margin-top: 30px;
      }
    }
    .content-before-share {
      padding-top: 20px;
      font-weight: bold;
      @include font-size(20px);
      @media #{$info-phone} {
        font-size: 14px;
      }
    }
  }
}
.btn-social-hidden {
  display: none !important;
}
</style>
